import React from 'react';
import Logo from '../images/logo jf.png';


const Jamiro = () => {
  const containerStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#fff',
    backgroundColor: '#000',
    minHeight: '100vh',
  };

  const logoStyle = {
    width: '80px',
    marginBottom: '20px',
  };

  const subtleStyle = {
    color: '#888888',
  };

  const nameStyle = {
    color: '#ffffff',
    fontWeight: 'bold',
  };


  const headingStyle = {
    fontSize: '24px',
    marginBottom: '24px',
    fontWeight: 'normal',
    color: '#888888',
  };

  const linkStyle = {
    color: '#ffffff',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const cardStyle = {
    backgroundColor: '#111111',
    borderRadius: '12px',
    padding: '20px',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'flex-start',
  };

  const iconPlaceholder = {
    width: '48px',
    height: '48px',
    backgroundColor: '#222222',
    borderRadius: '8px',
    marginRight: '16px',
    flexShrink: 0,
  };

  const cardContentStyle = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  };

  const cardTitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '4px',
  };

  const cardDescriptionStyle = {
    fontSize: '14px',
    color: '#888888',
    marginBottom: '0',
    maxWidth: '70%',
  };

  const buttonStyle = {
    padding: '8px 16px',
    backgroundColor: '#222222',
    color: '#ffffff',
    textDecoration: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    fontWeight: 'bold',
    alignSelf: 'flex-end',
    marginTop: 'auto',
  };

  return (
    <div style={containerStyle}>
      <img src={Logo} alt="Logo" style={logoStyle} />
      
      <h1 style={headingStyle}>
        I'm <span style={nameStyle}>Jamiro Ferguson</span>, <span style={subtleStyle}>I build and actively create solutions using Software, APIS, and code.</span>
      </h1>

      <p style={headingStyle}>
        Currently I'm growing Kiko, <span style={subtleStyle}>a product discovery, analytic, and creator insight platform focused on helping affiliate marketers and dropshippers discover trending and winning products in the market.</span>
      </p>

      <p style={headingStyle}>
        <span style={subtleStyle}>I build software and document it all publicly and share some of my insights on</span> <a href="https://www.youtube.com/@jamiro_999" style={linkStyle}>Youtube</a> <span style={subtleStyle}>&</span> <a href="https://www.instagram.com/mirocantcode/" style={linkStyle}>Instagram</a>. 
      </p>

      {/* Community Section - Commented Out
      <div style={cardStyle}>
        <div style={iconPlaceholder}></div>
        <div style={cardContentStyle}>
          <h2 style={cardTitleStyle}>Join my community (free)</h2>
          <p style={cardDescriptionStyle}>Where I share my learnings, host online/offline events & more</p>
        </div>
        <a href="#" style={buttonStyle}>Join</a>
      </div>
*/}

      {/* Kiko Section */}
      <div style={cardStyle}>
  <div style={iconPlaceholder}></div>
  <div style={cardContentStyle}>
    <h2 style={cardTitleStyle}>Kiko</h2>
    <p style={cardDescriptionStyle}>
      Advanced analytics and insights for e-commerce platforms including Shopify, Amazon, AliExpress, and more. 
      Analyze stores, track products, and uncover market trends.
    </p>
  </div>
  <a href="" style={buttonStyle}>Learn more</a>
</div>

      {/* CvAnalyst Section */}
      <div style={cardStyle}>
        <div style={iconPlaceholder}></div>
        <div style={cardContentStyle}>
          <h2 style={cardTitleStyle}>CvAnalyst</h2>
          <p style={cardDescriptionStyle}>Tailor your resumes in seconds, Land more interviews</p>
        </div>
        <a href="https://www.cvanalyst.io" style={buttonStyle}>Learn more</a>
      </div>
    </div>
  );
};

export default Jamiro;